// video aspect ratios - for calculation of (closest) aspect ratio from media dimensions
const videoAspectRatios = [
	{ ratio: 0.56, label: '9:16' },		// i.e. 720x1280 / 1080x1920 / 2160x3840 (UHD)
	{ ratio: 0.75, label: '3:4' },		// i.e. 480x640 / 960x1280
	{ ratio: 0.8, label: '4:5' },		// i.e. 1080x1350 / Instagram
	{ ratio: 1, label: '1:1' },			// i.e. 1080x1080 / square / div. social
	{ ratio: 1.25, label: '5:4' },		// i.e. 1350x1080 / Instagram
	{ ratio: 1.33, label: '4:3' },		// i.e. 640x480 / 1280x960
	{ ratio: 1.77, label: '16:9' },		// i.e. 1280x720 / 1920x1080 / 3840x2160 (UHD)
	{ ratio: 1.9, label: '1.90:1' },	// i.e. 4096x2160 / 4K
	{ ratio: 2.37, label: '21:9' }		// i.e. 2560x1080 / ultra widescreen / cinematic
]

const ratioMargin = .022 // allowed deviation from defined ratio values

// get video aspect ratio label
export default function getAspectRatioLabel(ratio) {
	let closest = videoAspectRatios[0]
	let closestDiff = Math.abs(ratio - closest.ratio)
	for (let i=1; i<videoAspectRatios.length; i++) {
		let current = videoAspectRatios[i]
		let currentDiff = Math.abs(ratio - current.ratio)
		if (currentDiff < closestDiff) {
			closest = current
			closestDiff = currentDiff
		}
	}
	return closestDiff < ratioMargin ? closest.label : 'Other'
}
