import React from 'react'
import { assetTypes } from '../../../utils/assetTypes'
import { industries } from '../../../utils/industries'
import getAspectRatioLabel from '../../../utils/videoAspectRatios'
import flowsamLogo from '../../../assets/images/flowsam-logo.svg'
import styles from'./styles.module.css'

// component function
export default function ReportHeader(props) {

	const { video } = props

	// find main-industry and subindustry by subindustry code
	const industry = industries.find(ind => ind.subindustries.find(sub => sub.id === video.industrySubCode))
	const subIndustry = industry?.subindustries?.find(sub => sub.id === video.industrySubCode)
	const industryLabel = industry?.label || 'n/a'
	const subIndustryLabel = subIndustry?.label || 'n/a'

	// find asset type & icon by targetPlatform id
	const assetType = assetTypes.find(type => type.id === video.targetPlatform)
	const assetTypeLabel = assetType?.label || 'n/a'
	const assetTypeIcon = require('../../../assets/images/media-type-icons/' + assetType?.iconHighlight) || null

	// analysis creation date
	const dateOptions = { year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric' }
	const date = video?.createdAt ? new Date(video.createdAt).toLocaleDateString("en-GB", dateOptions) : null

	// video duration
	const duration = (video.analysisType !== 3 && // not still image
		<div className={styles.detail}>
			<div className={styles.detailLabel}>Duration</div>
			<div className={styles.detailText}>{Math.round(video.frameCount/video.frameRate)} seconds</div>
		</div>
	)

	const format = video.analysisType === 3 ? 'Image (' + video.width + ' x ' + video.height + ' px)' : getAspectRatioLabel(video.width/video.height) + ' Video'

	return (
		<div className={styles.background}>
			<div className={styles.header}>
			<div className={styles.heading}>Ad performance report</div>
				<img src={flowsamLogo} className={styles.logo} alt="Flowsam logo" />
			</div>
			<div className={styles.detailsWrapper}>
				<div className={styles.icon}>
					<img src={assetTypeIcon} style={{marginTop: assetType?.vpos * 5}} alt="" />
				</div>
				<div className={styles.detailsGroupsWrapper}>
					<div>
					<div className={styles.detail}>
							<div className={styles.detailLabel}>Brand</div>
							<div className={styles.detailText}>{video.brand?.name}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Sub brand</div>
							<div className={styles.detailText}>{video.subbrand === 'NULL' || video.subbrand === '' ? 'n/a' : video.subbrand}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Ad name</div>
							<div className={styles.detailText}>{video.title}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Project</div>
							<div className={styles.detailText}>{video.project?.name}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Industry</div>
							<div className={styles.detailText}>{industryLabel}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Sub-industry</div>
							<div className={styles.detailText}>{subIndustryLabel}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Comments</div>
							<div className={styles.detailText}>{video.comments === 'NULL' || video.comments === '' ? 'n/a' : video.comments}</div>
						</div>
					</div>
					<div>
					<div className={styles.detail}>
							<div className={styles.detailLabel}>Version</div>
							<div className={styles.detailText}>{video.version === 'NULL' || video.version === '' ? 'n/a' : video.version}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Test date</div>
							<div className={styles.detailText}>{date}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Asset type</div>
							<div className={styles.detailText}>{assetTypeLabel}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Format</div>
							<div className={styles.detailText}>{format}</div>
						</div>
						{duration}
						<div className={styles.detail}>
							<div className={styles.detailLabel}>File name</div>
							<div className={styles.detailText}>{video.filename}</div>
						</div>
						<div className={styles.detail}>
							<div className={styles.detailLabel}>Analysis type</div>
							<div className={styles.detailText}>{video.ownanalysis === 0 ? 'Competitor ad' : 'Own ad'}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
