import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles.module.css'

// component function
export default function Todo(props) {

	const { analyses, tourRef } = props
	const history = useHistory()
	const unfinishedAnalyses = analyses?.filter(a => (a.currentState === 'complete' && !a.frontendState?.status) || a.frontendState?.status === 'work-in-progress')
	const items = unfinishedAnalyses?.sort((a, b) => b.updatedAt - a.updatedAt).slice(0,2) // two latest UNFINISHED analyses sorted by latest first

	// go to tagging
	function goTagging(guid) {
		history.push('/' + guid)
	}

	const todoList = items?.map((item,i) => { 
		const imgSrc = process.env.REACT_APP_GCS_BUCKET_URL + '/' + item.guid + '/thumb.jpg'
		const bgImageStyle = {
			backgroundImage:'url(' + imgSrc + ')', 
			backgroundSize: (item?.height/item?.width) <= .75 ? '190px ' + item?.height/item?.width * 190 + 'px' : 'cover' // scale up to hide black letterbox bars
		}
		return (
			<div key={i} className={styles.itemContainer} onClick={e=>goTagging(item.guid)}>
				<div className={styles.thumb} style={bgImageStyle} />
				<div className={styles.titleContainer}>
					<div className={styles.title}>{item.title}</div>
					<div className={styles.cta}>{item.frontendState?.status === 'work-in-progress' ? 'Continue tagging >' : 'Add brand cues >'}</div>
				</div>
			</div>
		)
	})

	const listContent = items ? items.length > 0 ? todoList : <div className={styles.noAnalyses}>No analyses to tag</div> : null

	return (
		<div ref={tourRef} className={styles.container}>
			<div className={styles.heading}>Todo</div>
			{listContent}
		</div>
	)
}
