import React, { useState, useEffect, useRef, useContext } from 'react'
import { APIContext } from '../../../utils/api'
import Switch from 'react-switch'
import AnimateHeight from 'react-animate-height'
import DropDown from '../../common/DropDown'
import Alert from '../../common/Alert'
import InfoBubble from '../../common/InfoBubble'
import { colors } from '../../../themes/colors'
import { assetTypes } from '../../../utils/assetTypes'
import styles from './styles.module.css'

// component function
export default function AdInfo(props) {

	const { mediaFile, assetTypeId, subIndustryId, submitFormData } = props

	const context = useContext(APIContext)
	const [height, setHeight] = useState(0)
	const [projectOptions, setProjectOptions] = useState([])
	const [brandOptions, setBrandOptions] = useState([])
	const [title, setTitle] = useState('')
	const [brand, setBrand] = useState('')
	const [subBrand, setSubBrand] = useState('')
	const [ownAnalysis, setOwnAnalysis] = useState(true)
	const [project, setProject] = useState('')
	const [version, setVersion] = useState('')
	const [comments, setComments] = useState('')
	const [alertTask, setAlertTask] = useState()
	const alertObjectRef = useRef(null)
	const titleFromFileRef = useRef('')

	// alert setup
	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	// reveal form if we have a sub-industry
	subIndustryId && height === 0 && setHeight('auto') // reveal content if we have subIndustry id

	// en-/disable submit button
	const canSubmit = title && brand && project && mediaFile && assetTypeId && subIndustryId

	// get project- and brand list
	useEffect(() => {
		// projects
		let isCancelled = false
		context.io.socket.get('/api/v1/project', (data, res) => {
			if (!isCancelled) {
				if (res.statusCode === 200) {
					const projects = data.map(project => {
						return { value:project.id, label:project.name }
					})
					setProjectOptions(projects)
				} else {
					// TODO: error handling
				}
			}
		})
		// brands
		context.io.socket.get('/api/v1/brand', (data, res) => {
			if (!isCancelled) {
				if (res.statusCode === 200) {
					const brands = data.map(brand => {
						return { value:brand.id, label:brand.name }
					})
					setBrandOptions(brands)
				} else {
					// TODO: error handling
				}
			}
		})
		return () => isCancelled = true // cleanup on unmount
	}, [context.io.socket])

	// prefill title input field with parsed file name if title is empty or title is generated from filename
	useEffect(() => {
		let parsedTitle = mediaFile?.name
			.replace(/\.[^/.]+$/, '') // remove file extension
			.replace(/_/g,' ')
			.replace(/ - /g,'_')
			.replace(/-/g,' ')
			.replace(/_/g,' - ')
		parsedTitle = parsedTitle?.charAt(0).toUpperCase() + parsedTitle?.slice(1) // uppercase first letter
		if (mediaFile && (title === '' || title === titleFromFileRef.current)) {
			titleFromFileRef.current = parsedTitle
			setTitle(parsedTitle)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaFile])

	// toggle analysis type handler
	function toggleAnalysisType() {
		setOwnAnalysis(!ownAnalysis)
	}

	// validate form data and submit if all ok
	function validateAndSubmit() {
		if (!title.trim()) {
			showAlert('Please enter a name for your analysis')
		} else if (!brand) {
			showAlert('Please select or create a brand for your analysis')
		} else if (!project) {
			showAlert('Please select or create a project for your analysis')
		} else if (!mediaFile) {
			showAlert('Please add a valid video or image (in step 1)')
		} else if (!assetTypeId) {
			showAlert('Please select an asset type for your analysis\n(in step 2)')
		} else if (!subIndustryId) {
			showAlert('Please select an industry for your analysis\n(in step 3)')
		} else if (canSubmit) {
			const formData = {
				video: mediaFile,
				analysisType: assetTypes.find(type => type.id === assetTypeId)?.analysisType,
				title: title,
				brand: brand,
				subbrand: subBrand,
				project: project,
				comments: comments,
				version: version,
				targetPlatform: assetTypeId,
				industrySubCode: subIndustryId,
				ownanalysis: ownAnalysis
			}
			submitFormData(formData)
		}
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()})
	}

	// analysis type toggle
	const analysisTypeToggle = (
		<div className={styles.AnalysisTypeContainer}>
			<div className={styles.switchContainer}>
				<div className={ownAnalysis ? styles.enabled : styles.disabled} onClick={toggleAnalysisType}>Own ad</div>
				<Switch
					onChange={toggleAnalysisType}
					checked={!ownAnalysis}
					offColor={colors.switchBackground0}
					offHandleColor={colors.switchKnobOn}
					onColor={colors.switchBackground0}
					onHandleColor={colors.switchKnobOn}
					uncheckedIcon={false}
					checkedIcon={false}
					height={16}
					width={30}
					handleDiameter={16}
					activeBoxShadow=''
					id='analysis-type-switch'
				/>
				<div className={ownAnalysis ? styles.disabled : styles.enabled} onClick={toggleAnalysisType}>Competitor ad</div>
			</div>
			<InfoBubble info='Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed.' pos='top' size={200} dist={13} move={-176} />
		</div>
	)

	return (
		<>
			<div className={styles.sectionHeading}>4. Ad info</div>
			<AnimateHeight duration={800} height={height}>
				<div className={styles.container}>
					<div className={styles.formColumn}>
						<div>
							<div className={styles.inputLabel}>Analysis name</div>
							<input name="title" value={title} onChange={(e) => setTitle(e.target.value)} className={styles.inputText} />
						</div>
						<div>
							<div className={styles.inputLabel}>Brand</div>
							<DropDown options={brandOptions} onChange={(label) => setBrand(label)} searchable={true} placeholder="Select or create..." />
						</div>
						<div>
							<div className={styles.inputLabel}>Sub brand / product</div>
							<input name="subbrand" value={subBrand} onChange={(e) => setSubBrand(e.target.value)} className={styles.inputText} />
						</div>
						<div>
							<div className={styles.inputLabel}>Analysis type</div>
							{analysisTypeToggle}
						</div>
					</div>
					<div className={styles.formColumn}>
						<div>
							<div className={styles.inputLabel}>Project</div>
							<DropDown options={projectOptions} onChange={(label) => setProject(label)} searchable={true} placeholder="Select or create..." />
						</div>
						<div>
							<div className={styles.inputLabel}>Analysis version</div>
							<input name="version" value={version} onChange={(e) => setVersion(e.target.value)} className={styles.inputText} />
						</div>
						<div>
							<div className={styles.inputLabel}>Comments</div>
							<textarea name="comments" value={comments} onChange={(e) => setComments(e.target.value)} className={styles.inputText + ' ' + styles.inputTextArea} />
						</div>
					</div>
				</div>
				<div className={styles.submitButton + (!canSubmit ? ' ' + styles.disabled : '')} onClick={validateAndSubmit}>START ANALYSIS</div>
			</AnimateHeight>
			{alert}
		</>
	)
}
