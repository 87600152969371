import React, { useContext, useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { APIContext } from '../../../utils/api'
import { GlobalContext } from '../../../utils/globalState'
import { colors } from '../../../themes/colors'
import Gravatar from 'react-gravatar'
import UserSettingsMenu from '../UserSettingsMenu'
import Alert from '../../common/Alert'
import flowsamIcon from '../../../assets/images/flowsam-icon-white.svg'
import newAnalysisIcon from '../../../assets/images/icon-new-analysis.svg'
import myAnalysesIcon from '../../../assets/images/icon-my-analyses.svg'
//import userGuideIcon from '../../../assets/images/icon-user-guide.svg'
import editObjectsIcon from '../../../assets/images/icon-edit-objects.svg'
import downloadHeatmapIcon from '../../../assets/images/icon-download-heatmap.svg'
import shareReportIcon from '../../../assets/images/icon-share-report.svg'
import styles from './styles.module.css'

// component function
export default function Sidebar(props) {

	const { isReport, video } = props
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [settingsVisible, setSettingsVisible] = useState(false)
	const [alertTask, setAlertTask] = useState()
	const [editingUser, setEditingUser] = useState(false)
	const [hidden, setHidden] = useState(true)
	const dataRoot = globalState.userData ? globalState.userData : null
	const context = useContext(APIContext)
	const history = useHistory()
	const { guid,code } = useParams()
	const alertObjectRef = useRef(null)
	const profileFormChanged = useRef(false)
	const saveUserData = useRef()
	const ownVideo = video?.user?.id === globalState.userData?.id

	// init on mount
	useEffect(() => {
		//keyboard esc listener
		window.addEventListener("keydown", keyPressed)
		document.addEventListener('click', closeMenu)
		// cleanup on unmount
		return () => {
			window.removeEventListener("keydown", keyPressed)
			document.removeEventListener('click', closeMenu)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	// go to root page or login
	function goHome() {
		globalState.loggedIn ? history.push('/') :
		!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? history.push('/login') :
		window.location.href = process.env.REACT_APP_PUBLIC_URL
	}

	// log out
	function logout() {
		context.io.socket.post('/api/v1/user/logout', (data, res) => {
			if (res.statusCode === 200) {
				setGlobalState({...globalState, loggedIn: false })
				goLogin()
			} else {
				// TODO: error handling
			}
		})
	}

	// go to log in
	function goLogin() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			history.push('/login')
		} else {
			goPublic()
		}
	}

	// go to public site
	function goPublic() {
		window.location.href = process.env.REACT_APP_PUBLIC_URL
	}

	// request demo
	function getTrial() {
		window.location.href = process.env.REACT_APP_PUBLIC_URL + '/free-trial'
	}

	// close settings menu on on escape
	function keyPressed(e) {
		if (e.keyCode === 27) { // escape
			document.activeElement.blur() // remove input field focus
			checkAndCloseMenu()
		}
	}

	// toggle menu on avatar click
	function toggleMenu() {
		settingsVisible ? checkAndCloseMenu() : setSettingsVisible(true)
	}

	// toggle navBar visibility on mobile
	function toggleMobileNavbar() {
		setHidden(!hidden)
	}

	// close settings menu if clicked outside
	function closeMenu(e) {
		const menuContainer = document.getElementById('settingsMenu')
		const avatar = document.getElementById('avatar')
		if (!avatar?.contains(e.target) && !menuContainer?.contains(e.target)) {
			checkAndCloseMenu()
		}
	}

	// cancel changes to profile on menu close?
	function checkAndCloseMenu() {
		if (profileFormChanged.current) {
			alertObjectRef.current = { type:'confirm', title:'Save profile changes?' }
			setAlertTask(()=>(action)=>{ // define alert action and display alert
				if (action) {
					saveUserData.current(null, true) // save and close menu after succesful submit
				} else {
					profileFormChanged.current = false
					setSettingsVisible(false)
					setEditingUser(false)
				}
				setAlertTask() // remove alert
			})
		} else {
			profileFormChanged.current = false
			setSettingsVisible(false)
			setEditingUser(false)
		}
	}

	// get report link
	function getReportLink() {
		return new Promise((resolve) => {
			context.io.socket.get('/api/v1/videoshare/'+guid, (data, res) => {
				if (res.statusCode === 200) {
					const host = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_PUBLIC_URL
					const sharedLink = host + '/shared/' + data.guid + '/' + data.code
					resolve(sharedLink)
					alertObjectRef.current = { title:'Report public link:\n(has been copied to your clipboard)', message:sharedLink }
					setAlertTask(()=>(action)=>{setAlertTask()})
				} else {
					alertObjectRef.current = { title:'Sorry, couldn’t get link to shared report', message:'statusCode: ' + res.statusCode }
					setAlertTask(()=>(action)=>{setAlertTask()})
				}
			})
		})
	}

	// share report
	function shareReport() {
		if (typeof ClipboardItem && navigator?.clipboard?.write) {
			const text = new ClipboardItem( {"text/plain": getReportLink().then((text) => new Blob([text], {type: "text/plain"}))})
			navigator.clipboard.write([text])
		} else {
			getReportLink().then((text) => navigator.clipboard.writeText(text))
		}
	}

	const profileImage = (dataRoot?.organization?.settings?.avatar ? // do we have an organization avatar image?
		<div className={styles.avatar} style={{marginTop:'10px', backgroundImage:'url('+dataRoot.organization.settings.avatar+')'}} /> :
		dataRoot?.email && <Gravatar className={styles.avatar} style={{marginBottom:'7px'}} email={dataRoot.email} default="mp" />
	)

	const editObjectsMenu = (globalState.loggedIn && !code && ownVideo &&
		<div className={styles.menuIcon} onClick={e=>{history.push('/' + guid, {from: history.location.pathname})}}>  {/* add previous path to location state property */}
			<img src={editObjectsIcon} alt="Edit objects" />
			Edit objects
		</div>	
	)

	const downloadHeatmapMenu = (video?.bucketPathMp4Attention &&
		<div className={styles.menuIcon}>
			<a href={process.env.REACT_APP_GCS_BUCKET_URL + '/' + video.bucketPathMp4Attention} download={'attention-'+video.filename} style={{textDecoration:'none', color:colors.textInverse}}>
				<img src={downloadHeatmapIcon} style={{marginBottom: '-1px'}} alt={"Download heatmap " + (video?.analysisType === 3 ? 'image' : 'video')} />
				{"Download heatmap " + (video?.analysisType === 3 ? 'image' : 'video')}
			</a>
		</div>
	)

	const shareReportMenu = (globalState.loggedIn && !code && ownVideo &&
		<div className={styles.menuIcon} onClick={shareReport}>
			<img src={shareReportIcon} style={{marginBottom: '-2px'}} alt="Share report" />
			Share report
		</div>
	)

	const reportIcons = (isReport &&
		<>
			<div className={styles.menuDivider} />
			{editObjectsMenu}
			{downloadHeatmapMenu}
			{shareReportMenu}
		</>
	)

	const alert = alertObjectRef.current !== null && alertTask !== undefined && <Alert type={alertObjectRef.current.type} title={alertObjectRef.current.title} message={alertObjectRef.current.message} cancelLabel={alertObjectRef.current.cancelLabel} actionLabel={alertObjectRef.current.actionLabel} action={alertTask} />

	return (
		// not logged in
		globalState.loggedIn === undefined ?
			null
		:
		globalState.loggedIn === false ? // menu for public/shared report
			<div className={styles.sideBar + (!hidden ? ' ' + styles.mobileShow : '')}>
				<div className={styles.menuContainer}>
					<img src={flowsamIcon} className={styles.flowsamIcon} onClick={goHome} alt="Flowsam icon" />
					<div className={styles.menuDivider} />
					<div className={styles.menuIcon} onClick={goPublic}>
						About flowsam
					</div>
					<div className={styles.menuDivider} />
					<div className={styles.menuIcon} onClick={getTrial}>
						Free trial
					</div>
					<div className={styles.menuDivider} />
					<div className={styles.menuIcon} onClick={goLogin}>
						Log in
					</div>
					{reportIcons}
				</div>
				{profileImage}
				<div className={styles.mobileIcon} onClick={toggleMobileNavbar} />
			</div>
		:
		// logged in - show content
		<>
			<div className={styles.sideBar + (!hidden ? ' ' + styles.mobileShow : '')}>
				<div className={styles.menuContainer}>
					<img src={flowsamIcon} className={styles.flowsamIcon} onClick={e=>history.push('/')} title="Dashboard" alt="Flowsam icon" />
					<div className={styles.menuIcon} onClick={e=>history.push('/new-analysis')}>
						<img src={newAnalysisIcon} style={{margin: '2px 0 -2px'}} alt="New analysis" />
						New analysis
					</div>
					<div className={styles.menuIcon} onClick={e=>history.push('/my-analyses')}>
						<img src={myAnalysesIcon} style={{margin: '9px 3px 4px 0'}} alt="My analyses" />
						My analyses
					</div>
					{/*<div className={styles.menuIcon} onClick={e=>history.push('/userguide')}>
						<img src={userGuideIcon} style={{marginBottom: '-2px'}} alt="User guide" />
						User guide
					</div>*/}
					{reportIcons}
				</div>
				<div className={styles.menuContainer}>
					<div id="avatar" className={styles.menuIcon} onClick={toggleMenu}>
						{profileImage}
						Profile & account
					</div>
				</div>
				<div className={styles.mobileIcon} onClick={toggleMobileNavbar} />
			</div>
			<div id='settingsMenu'>
				<UserSettingsMenu profileFormChanged={profileFormChanged} saveUserData={saveUserData} settingsVisible={settingsVisible} setSettingsVisible={setSettingsVisible} editingUser={editingUser} setEditingUser={setEditingUser} logout={logout} />
			</div>
			{alert}
		</>
	)
}
