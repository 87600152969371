import React from 'react'
import { colors } from '../../themes/colors'

// styles
const progressContainerStyle = {
	marginBottom: '7px'
}

const backBarStyle = {
	width: '100%',
	maxWidth: '408px',
	height: '11px',
	margin: '0 auto',
	backgroundColor: colors.progressbarBack,
	borderRadius: '6px'
}

// component function
export default function AnalysisProgressBar(props) {

	const { label, pct, id } = props

	// dynamic styles
	const progressLabelStyle = {
		width: '100%',
		maxWidth: '408px',
		textAlign: 'center',
		margin: '15px auto 0',
		color: colors.text,
		opacity: pct === 0 ? '.6' : '1'
	}

	const frontBarStyle = {
		height: '11px',
		backgroundColor: colors.progressbarFill,
		borderRadius: '6px',
		opacity: pct === 100 ? '1' : '.75',
		width: pct + '%',
		minWidth:  pct > 0 ? '11px' : '0',
		transition: pct > 0 ? 'width 1s' : 'width 0s'
	}

	const barLabel = label && <h4 style={progressLabelStyle}>{label}</h4> // + ' ' + Math.round(pct) + '%'}</h4>

	return (
		<div id={id} style={progressContainerStyle}>
			<div style={backBarStyle}>
				<div style={frontBarStyle} />
			</div>
			{barLabel}
		</div>
	)
}
