import React, { useContext, useState, useRef, useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import Alert from '../common/Alert'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'
import splash20pct from '../../assets/images/splash-20-pct.svg'

// styles
const switcherContainerStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '1px',
	paddingLeft: '70px'
}

const switcherStyle = {
	width: '40px',
	height: '22px',
	paddingTop: '2px',
	paddingLeft: '20px',
	boxSizing: 'border-box',
	borderRadius: '11px',
	backgroundColor: colors.switchBackground1,
	margin: '0 12px',
	cursor: 'pointer',
	transition: 'padding-left .3s'
}

const disabledStyle = {
	opacity: '.5',
	cursor: 'pointer'
}

const subColumnsContainerStyle = {
	display: 'flex',
	alignItems: 'stretch',
	margin: '18px -12px 0'
}

const subColumnHeaderStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '83px',
	boxSizing: 'border-box',
	backgroundColor: colors.background3,
	color: colors.textInverse,
	padding: '1px 22px 0'
}

const boldHeaderStyle = {
	fontFamily: 'Greycliff demibold',
	lineHeight: '24px',
	textAlign: 'center',
	whiteSpace: 'nowrap'
}

const bulletStyle = {
	width: '18px',
	height: '18px',
	borderRadius: '50%',
	backgroundColor: colors.background3,
	margin: '12px auto 21px'
}

const subscribeButtonStyle = {
	marginTop: 'auto',
	letterSpacing: '.3px',
	borderRadius: '0',
	padding: '14px 15px 14px'
}

const subscribeButtonSmallStyle = {
	...subscribeButtonStyle,
	fontSize: '12px',
	fontFamily: 'Greycliff demibold',
	whiteSpace: 'nowrap'
}

const subTypeTxtStyle = {
	fontFamily: 'Greycliff',
	cursor: 'default',
	transition: 'opacity .3s',
	color: colors.text
}

const switcherKnobStyle = {
	width: '18px',
	height: '18px',
	borderRadius: '50%',
	backgroundColor: colors.switchKnobOn
}

const subColumnStyle = {
	display: 'flex',
	flexDirection: 'column',
	margin: '0 12px',
	flexBasis: '175px',
	backgroundColor: colors.background2
}

const subColumnTxtStyle = {
	padding: '15px 24px 18px',
	fontSize: '18px',
	lineHeight: '39px',
	textAlign: 'center',
	whiteSpace: 'nowrap',
	color: colors.text
}

const labelStyle = {
	fontSize: '12px',
	lineHeight: '16px',
	fontFamily: 'Greycliff demibold',
	color: colors.textLabel,
	whiteSpace: 'nowrap'
}

// component function
export default function AccountSubscriptionPlans(props) {

	const {org, plans} = props
	const currentPlan = org.subscriptionPlan
	const calculations = org.subscriptionCalculations
	const country = org.companyStoreData?.customer?.address?.country
	const taxFactor = country && country === 'DK' ? .25 : 0
	const taxTxt = taxFactor > 0 ? '(incl. ' + (taxFactor * 100) + '% tax) ' : ' '
	const context = useContext(APIContext)
	const [globalState, setGlobalState] = useContext(GlobalContext)
	const [alertTask, setAlertTask] = useState()
	const [yearlySelected, setYearlySelected] = useState(!currentPlan.config.Monthly_billing)
	const [showFeatures, setShowFeatures] = useState(false)
	const alertObjectRef = useRef(null)
	const submittingRef = useRef(false)
	const stripe = useStripe()
	const dateOptionsWithYear = { year:'numeric', month:'short', day:'numeric' }
	const subEndDate = new Date(calculations.subEnd) // date for yearly renewal

	const alert = (alertObjectRef.current !== null && alertTask !== undefined &&
		<Alert
			type={alertObjectRef.current.type}
			title={alertObjectRef.current.title}
			message={alertObjectRef.current.message}
			cancelLabel={alertObjectRef.current.cancelLabel}
			actionLabel={alertObjectRef.current.actionLabel}
			waitingLabel={alertObjectRef.current.waitingLabel}
			useReturnKey={alertObjectRef.current.useReturnKey}
			action={alertTask}
		/>
	)

	// dynamic styles
	const subColumnTxtFeaturesStyle = {
		...subColumnTxtStyle,
		borderTop: showFeatures ? '1px solid ' + colors.background0 : 'none',
		maxHeight: showFeatures ? '700px' : '0',
		padding: showFeatures ? '15px 30px 18px' : '0 30px 0',
		transition: 'max-height .7s, padding-top .7s, padding-bottom .7s',
		overflow: 'hidden'
	}

	// listen for succesful payment event from flowsam api
	useEffect(() => {
		context.io.socket.on('invoice.payment_succeeded', updateUserAfterPaymentSucceeded)
		return () => context.io.socket.off('invoice.payment_succeeded', updateUserAfterPaymentSucceeded) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[context.io.socket])

	// refresh user data to global state and show receipt after succesful subscription change
	function updateUserAfterPaymentSucceeded() {
		context.io.socket.get('/api/v1/user', (data, res) => {
			setAlertTask() // remove confirm dialogue
			if (res.statusCode === 200) {
				setGlobalState({...globalState, userData:data})
				alertObjectRef.current = { title:'Congratulations!\nYou are now on a ' + getPlan(data.organization.subscriptionPlan.id).name + (data.organization.subscriptionPlan.config.Monthly_billing ? ' - monthly plan' :  ' - yearly plan'), message:'See all subscription details at the top of the page.' }
				setAlertTask(()=>(action)=>{setAlertTask()}) // execute alert
			} else {
				showAlert('Error refreshing data after succesful subscription change: ' + res.error.message) // show error dialogue
			}
			submittingRef.current = false
		})
	}

	// add thousand separators to number
	function numberWithCommas(val) {
	    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}

	// show alert
	function showAlert(msg) {
		alertObjectRef.current = { title:msg }
		setAlertTask(()=>(action)=>{setAlertTask()}) // execute alert
	}

	// plan features by id
	function getPlan(id) {
		const plan = plans.find(pl => pl.id === id)
		return {
			externalId: plan.externalId,
			name: plan.name,
			price: yearlySelected ? plan.config.Monthly_ex_VAT_Annual_billing : plan.config.Monthly_ex_VAT_Monthly_billing,
			videos: plan.config.Total_videos_month,
			seats: plan.config.User_seats,
			secs: plan.config.Max_film_length,
			mb: plan.config.Max_Mb_per_film
		}
	}

	// subscription button
	function getSubscriptionButton(id) {
		const config = plans.find(pl => pl.id === id).config
		const price = yearlySelected ? config.Monthly_ex_VAT_Annual_billing : config.Monthly_ex_VAT_Monthly_billing
		const currentPrice = yearlySelected ? currentPlan.config.Monthly_ex_VAT_Annual_billing : currentPlan.config.Monthly_ex_VAT_Monthly_billing
		const cancelDate = new Date(subEndDate)
		cancelDate.setMonth(cancelDate.getMonth()-1) // one month before yearly renewal

		// button text
		let disabled = false
		let btnTxt = 'SELECT'
		let btnStyle = subscribeButtonStyle
		let btnAction = null
		if (currentPlan.id === id && !(calculations.subCancel !== 0 || calculations.subExpired)) { // current subscription (not canceled or expired)
			disabled = true
			btnTxt = 'CURRENT'
		} else if (currentPlan.config.Annual_billing && (price <= currentPrice || !yearlySelected) && !(calculations.subCancel !== 0 || calculations.subExpired || cancelDate < new Date())) { // if currently on a yearly plan that is not canceled or expired or not within a month of renewal, disable button if plan is cheaper or monthly
			disabled = true
			btnTxt = 'AVAILABLE ' + cancelDate.toLocaleDateString("en-GB", dateOptionsWithYear).toUpperCase()
			btnStyle = subscribeButtonSmallStyle
		}

		// cancel text below button
		let cancelTxt = <>&nbsp;</>
		if (currentPlan.id === id && !(calculations.subCancel !== 0 || calculations.subExpired)) { // current subscription (not canceled or expired)
			if (yearlySelected) { // yearly subscriptions selected
				if (cancelDate < new Date()) { // less than a month to renewal
					btnAction = cancelSubscription
					cancelTxt = 'Cancel subscription' // current subscription can be cancelled
				} else {
					cancelTxt = 'Cancelable from ' + cancelDate.toLocaleDateString("en-GB", dateOptionsWithYear)
				}
			} else { // monthly subscription
				btnAction = cancelSubscription
				cancelTxt = 'Cancel subscription'
			}
		}
		return (
			<>
				<button tabIndex="-1" className="fs-button" disabled={disabled} style={btnStyle} onClick={e=>changeSubscription(id)}>{btnTxt}</button>
				<div style={{backgroundColor:colors.background0, paddingTop:'10px', paddingBottom:'6px', cursor:btnAction ? 'pointer' : 'auto'}} onClick={btnAction}><p style={labelStyle}>{cancelTxt}</p></div>
			</>
		)
	}

	// change subscription
	function changeSubscription(id) {
		const renewDate = new Date()
		yearlySelected ? renewDate.setFullYear(renewDate.getFullYear()+1) : renewDate.setMonth(renewDate.getMonth()+1)
		const analysesLeft = currentPlan.config.Total_videos_month - calculations.currentPeriodAnalysesUsed
		const freeAnalysesLeft = org.freeAnalyses && org.freeAnalyses > 0 ?  'and ' + org.freeAnalyses + ' extra ' : ''
		const analysesLeftTxt = analysesLeft > 0 ? '\nYou have ' + analysesLeft + ' monthly ' + freeAnalysesLeft + 'analyses left on your current subscription. They will be transferred to your new subscription as non-expiring extra analyses.' : ''
		alertObjectRef.current = {
			type:'confirm',
			title: (calculations.subCancel !== 0 || calculations.subExpired ? 'Subscribe to ' : 'Change plan to ') + getPlan(id).name + (yearlySelected ? ' - yearly?' :  ' - monthly?'),
			message:'Your subscription will start today and you will be billed ' + numberWithCommas(getPlan(id).price * (1+taxFactor)) + ' € ' + taxTxt + 'monthly in advance. The subscription will automatically renew on ' + renewDate.toLocaleDateString("en-GB", dateOptionsWithYear) + '. Terms apply.' + analysesLeftTxt,
			cancelLabel:'Don’t change',
			actionLabel:'Yes, pay ' + numberWithCommas(getPlan(id).price * (1+taxFactor)) + ' €',
			waitingLabel:'Paying...',
			useReturnKey: false
		}
		// show confirm alert
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // submit subscription update
				if (!submittingRef.current) {
					submittingRef.current = true
					context.io.socket.post('/api/v1/subscription/update', { priceId: getPlan(id).externalId }, (data, res) => {
						if (res.statusCode === 200) {
							if (data.status === 'active') { // we’re done
								// wait for 'invoice.payment_succeeded' socket-event from Flowsam API
							} else if (data.status === 'incomplete') { // we need payment confirmation
								stripe.confirmCardPayment(data.latest_invoice.payment_intent.client_secret).then(result => {
									// payment confirmation response
									if (result.error) {
										setAlertTask() // error - remove confirm dialogue
										showAlert('Error updating subscription: ' + result.error.message) // show error dialogue
										submittingRef.current = false
									} else { // now we’re done
										// wait for 'invoice.payment_succeeded' socket-event from Flowsam API
									}
								})
							} else {
								setAlertTask() // error - remove confirm dialogue
								showAlert('Error updating subscription: ' + res.error.message) // show error dialogue
								submittingRef.current = false
							}
						} else if (res.statusCode === 410) { // no credit card
							setAlertTask() // error - remove confirm dialogue
							showAlert('Sorry, you need to add a valid credit card to your account (further down this page) before changing subscription') // show error dialogue
							submittingRef.current = false
						} else {
							setAlertTask() // error - remove confirm dialogue
							showAlert('Error updating subscription: ' + res.error.message) // show error dialogue
							submittingRef.current = false
						}
					})
				}
			} else {
				setAlertTask() // cancel - remove confirm dialogue
				submittingRef.current = false
			}
		})
		// TODO: link to Terms
	}

	// cancel subscription
	function cancelSubscription() {
		alertObjectRef.current = {
			type:'confirm',
			title:'Cancel your flowsam subscription?',
			message:'Your subscription will expire on ' + subEndDate.toLocaleDateString("en-GB", dateOptionsWithYear) + '.\nYou must spend remaining analyses before this date.\nYour organization account(s) will remain active so you can still log in and view your reports after the subscription expires.',
			cancelLabel:'Keep subscription',
			actionLabel:'Cancel subscription',
			waitingLabel:'Canceling...',
			useReturnKey: false
		}
		// show cancel confirm alert
		setAlertTask(()=>(action)=>{ // execute alert
			if (action) { // submit subscription cancel
				if (!submittingRef.current) {
					submittingRef.current = true
					context.io.socket.post('/api/v1/subscription/cancel', {}, (data, res) => {
						if (res.statusCode === 200) {
							context.io.socket.get('/api/v1/user', (data, res) => {
								setAlertTask() // remove confirm dialogue
								if (res.statusCode === 200) {
									setGlobalState({...globalState, userData:data})
									alertObjectRef.current = { title:'Your flowsam subscription has been canceled and will expire on ' + subEndDate.toLocaleDateString("en-GB", dateOptionsWithYear) + '.', message:'You must spend remaining analyses before this date. After expiration your account will remain active so you can still log in and view your reports. And of course make a new subscription.' }
									setAlertTask(()=>(action)=>{setAlertTask()}) // execute alert
								} else {
									showAlert('Error refreshing data after succesful subscription cancel: ' + res.error.message) // show error dialogue
								}
								submittingRef.current = false
							})
						} else {
							setAlertTask() // error - remove confirm dialogue
							showAlert('Error canceling subscription: ' + res.error.message) // show error dialogue
							submittingRef.current = false
						}
					})
				}
			} else {
				setAlertTask() // cancel - remove confirm dialogue
				submittingRef.current = false
			}
		})
	}

	const splashStyle = yearlySelected ? {...subTypeTxtStyle, transition:'opacity .3s'} : {...subTypeTxtStyle, ...disabledStyle, transition:'opacity .3s'}

	const smallText = (showFeatures &&
		<>
			<div>** Industry benchmarking may not be available in all industry categories.</div>
			<div>*** Same features as normal analyses, except industry benchmarking.</div>
		</>
	)

	return (
		<>
			<h2 style={{color:colors.text, marginTop:'-7px'}}>Flowsam subscription plans</h2>
			<div style={switcherContainerStyle}>
				<h4 style={yearlySelected ? {...subTypeTxtStyle, ...disabledStyle} : subTypeTxtStyle} onClick={e=>setYearlySelected(false)}>Monthly subscription</h4>
				<div style={{...switcherStyle, paddingLeft: yearlySelected ? '20px' : '2px' }} onClick={e=>setYearlySelected(!yearlySelected)}>
					<div style={switcherKnobStyle} />
				</div>
				<h4 style={yearlySelected ? subTypeTxtStyle : {...subTypeTxtStyle, ...disabledStyle}} onClick={e=>setYearlySelected(true)}>Yearly subscription *</h4>
				<img style={splashStyle} src={splash20pct} onClick={e=>setYearlySelected(true)} alt="Discount splash"/>
			</div>
			<div style={subColumnsContainerStyle}>
				<div style={{...subColumnStyle, flexGrow:'1'}}>
					<div style={{...subColumnHeaderStyle, justifyContent:'left', flexDirection:'row', paddingLeft:'30px', paddingRight:'30px'}}>
						<h3 style={{...boldHeaderStyle, textAlign:'left'}}>Price/month <span style={{fontSize:'18px'}}>(ex.&nbsp;VAT)</span></h3>
					</div>
					<div style={{...subColumnTxtStyle, textAlign:'left', paddingLeft:'30px', paddingRight:'30px'}}>
						Video analyses/month<br/>
						{/*User seats<br/>*/}
						Max. video length<br/>
						Max. file size per video
					</div>
					<div style={{...subColumnTxtFeaturesStyle, textAlign:'left'}}>
						Object detection<br/>
						Manual object creation<br/>
						Brand cue tagging<br/>
						Attention heatmaps<br/>
						Brand scores/metrics<br/>
						Retention effect insights<br/>
						Personal benchmarking<br/>
						Report &amp; report sharing<br/>
						Unlimited storage<br/>
						Email support<br/>
						Online user guide<br/>
						Download attention video<br/>
						Industry benchmarking **<br/>
						Onboarding session<br/>
						Dedicated support team<br/>
						Animatic analyses ***
					</div>
					<button tabIndex="-1" className="fs-button" style={subscribeButtonStyle} onClick={e=>setShowFeatures(!showFeatures)}>{showFeatures ? 'HIDE' : 'SHOW'} ALL FEATURES</button>
					<div style={{backgroundColor:colors.background0, paddingTop:'9px', paddingBottom:'6px'}}><p style={labelStyle}>&nbsp;</p></div>
				</div>
				<div style={subColumnStyle}>
					<div style={subColumnHeaderStyle}>
						<h4 style={{...boldHeaderStyle, marginBottom:'-4px'}}>{getPlan(4).name.toUpperCase()}</h4>
						<h2 style={{textAlign:'center', wordSpacing:'-.1em', color:colors.textInverse}}>{numberWithCommas(getPlan(4).price)}&nbsp;€</h2>
					</div>
					<div style={subColumnTxtStyle}>
						{getPlan(4).videos}<br/>
						{/*{getPlan(4).seats}<br/>*/}
						{getPlan(4).secs} secs<br/>
						{getPlan(4).mb} MB
					</div>
					<div style={subColumnTxtFeaturesStyle}>
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={{fontSize:'14px', lineHeight:'22px' , color:colors.link, marginTop:'-3px'}}>Watermarked</div>
					</div>
					{getSubscriptionButton(yearlySelected ? 4 : 8)}
				</div>
				<div style={subColumnStyle}>
				<div style={subColumnHeaderStyle}>
						<h4 style={{...boldHeaderStyle, marginBottom:'-4px'}}>{getPlan(3).name.toUpperCase()}</h4>
						<h2 style={{textAlign:'center', wordSpacing:'-.1em', color:colors.textInverse}}>{numberWithCommas(getPlan(3).price)}&nbsp;€</h2>
					</div>
					<div style={subColumnTxtStyle}>
						{getPlan(3).videos}<br/>
						{/*{getPlan(3).seats}<br/>*/}
						{getPlan(3).secs} secs<br/>
						{getPlan(3).mb} MB
					</div>
					<div style={subColumnTxtFeaturesStyle}>
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
					</div>
					{getSubscriptionButton(yearlySelected ? 3 : 7)}
				</div>
				<div style={subColumnStyle}>
				<div style={subColumnHeaderStyle}>
						<h4 style={{...boldHeaderStyle, marginBottom:'-4px'}}>{getPlan(2).name.toUpperCase()}</h4>
						<h2 style={{textAlign:'center', wordSpacing:'-.1em', color:colors.textInverse}}>{numberWithCommas(getPlan(2).price)}&nbsp;€</h2>
					</div>
					<div style={subColumnTxtStyle}>
						{getPlan(2).videos}<br/>
						{/*{getPlan(2).seats}<br/>*/}
						{getPlan(2).secs} secs<br/>
						{getPlan(2).mb} MB
					</div>
					<div style={subColumnTxtFeaturesStyle}>
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={{...bulletStyle, marginBottom:'0'}} />
					</div>
					{getSubscriptionButton(yearlySelected ? 2 : 6)}
				</div>
				<div style={subColumnStyle}>
				<div style={subColumnHeaderStyle}>
						<h4 style={{...boldHeaderStyle, marginBottom:'-4px'}}>{getPlan(1).name.toUpperCase()}</h4>
						<h2 style={{textAlign:'center', wordSpacing:'-.1em', color:colors.textInverse}}>{numberWithCommas(getPlan(1).price)}&nbsp;€</h2>
					</div>
					<div style={subColumnTxtStyle}>
						{getPlan(1).videos}<br/>
						{/*{getPlan(1).seats}<br/>*/}
						{getPlan(1).secs} secs<br/>
						{getPlan(1).mb} MB
					</div>
					<div style={subColumnTxtFeaturesStyle}>
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={bulletStyle} />
						<div style={{...bulletStyle, marginBottom:'0'}} />
					</div>
					{getSubscriptionButton(yearlySelected ? 1 : 5)}
				</div>
			</div>
			<div style={{...labelStyle, lineHeight:'20px'}}>
				<br/>
				* Both monthly and yearly subscription will be invoiced on a monthly basis. Only difference is the binding period of the subscription.<br/>
				{smallText}
			</div>
			{alert}
		</>
	)
}
