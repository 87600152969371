import React, { useState, useEffect, useContext } from 'react'
import AnalysisListItem from'./AnalysisListItem'
import { APIContext } from '../../utils/api'
import { GlobalContext } from '../../utils/globalState'
import { colors } from '../../themes/colors'

// styles
const containerStyle = {
	width: '100%',
	minWidth: '768px',
	padding: '70px 50px 50px',
	textAlign: 'center',
	boxSizing: 'border-box',
	backgroundColor: colors.background1
}

const analysisListStyle = {
	display: 'flex',
	justifyContent: 'center',
	flexWrap: 'wrap',
	maxWidth: '2000px',
	margin: '0 auto',
	paddingTop: '53px'
}

const sortStyle = {
	display: 'flex',
	justifyContent: 'center',
	width: '438px',
	height: '20px',
	boxSizing: 'border-box',
	margin: '38px auto -36px',
	fontSize: '12px',
	fontFamily: 'Greycliff demibold'
}

const sortButtonStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: colors.text,
	opacity: '.5',
	height: '19px',
	padding: '0 11px',
	cursor: 'pointer',
	transition: 'opacity .2s'
}

const searchInputStyle = {
	marginLeft: '20px',
	color: colors.text
}

const closeButtonStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	paddingBottom: '1px',
	fontFamily: 'Greycliff',
	backgroundColor: colors.background0,
	fontSize: '24px',
	color: colors.textLabel,
	boxSizing: 'border-box',
	minWidth: '20px',
	height: '20px',
	borderRadius: '11px',
	cursor: 'pointer'
}

// highlight sort link
function onOver(e) {
	e.currentTarget.style.opacity = '1'
}

// dim sort link
function onOut(e) {
	e.currentTarget.style.opacity = '.5'
}

// component function
export default function AnalysisList(props) {

	const context = useContext(APIContext)
	const [items, setItems] = useState()
	const [globalState, setGlobalState] = useContext(GlobalContext)	// eslint-disable-line no-unused-vars
	const [searchVisible, setSearchVisible] = useState(false)
	const [searchInput, setSearchInput] = useState('')
	const [listSort, setListSort] = useState('dateDesc')
	const [forceUpdate, setForceUpdate] = useState(0)
	const [projectList, setProjectList] = useState()
	const [brandList, setBrandList] = useState()
	//const showTeamAnalyses = globalState.userData?.settings?.showTeamAnalyses ? globalState.userData.settings.showTeamAnalyses : false
	const showTeamAnalyses = false // removed team/seats // HJ
	const listLink = showTeamAnalyses ? '/api/v1/organization/video' : '/api/v1/video'
	const userId = globalState.userData?.id
	const userName = globalState.userData?.name

	// init on mount
	useEffect(() => {
		// get project list
		getProjectList()
		getBrandList()
		//keyboard esc listener
		window.addEventListener("keydown", keyPressed)
		return () => window.removeEventListener("keydown", keyPressed) // cleanup on unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
 	},[])

	// get analyses list
	useEffect(() => {
		context.io.socket.get(listLink, (data, res) => {
			if (res.statusCode === 200) {
				setItems(data.filter(item => (showTeamAnalyses ? item.user.id : item.user) === userId || (item.user.id !== userId && item.frontendState !== null && item.frontendState.status === 'finished'))) // dont include other team members’ analyses if they’re not completed
			} else {
				// TODO: error handling
			}
		})
	}, [context.io.socket, forceUpdate, props.update, listLink, userId, userName, showTeamAnalyses])

	// focus search field if search gets visible or clear search text if search field gets hidden
	useEffect(() => {
		const searchField = document.getElementById('searchField')
		searchVisible ? searchField.focus() : setSearchInput('')
	}, [searchVisible])

	// get project list
	function getProjectList() {
		context.io.socket.get('/api/v1/project', (data, res) => {
			if (res.statusCode === 200) {
				const projects = data.map(project => {
					return { value:project.id, label:project.name }
				})
				setProjectList(projects)
			} else {
				// TODO: error handling
			}
		})
	}

	// get brand list
	function getBrandList() {
		context.io.socket.get('/api/v1/brand', (data, res) => {
			if (res.statusCode === 200) {
				const brands = data.map(brand => {
					return { value:brand.id, label:brand.name }
				})
				setBrandList(brands)
			} else {
				// TODO: error handling
			}
		})
	}

	// clear search field on escape
	function keyPressed(e) {
		if (e.keyCode === 27) { // escape
			setSearchVisible(false)
		}
	}

	// sort list
	function sortList(e) {
		const label = e.currentTarget.innerText
		const sort = (
			label === 'Sort by date' ? listSort === 'dateDesc' ? 'dateAsc' : 'dateDesc' :
			label === 'Sort by title' ? listSort === 'titleAsc' ? 'titleDesc' : 'titleAsc' :
			label === 'Sort by brand' ? listSort === 'brandAsc' ? 'brandDesc' : 'brandAsc' :
			listSort === 'typeDesc' ? 'typeAsc' : 'typeDesc'
		)
		setListSort(sort)
	}

	// filter list on search input
	function updateSearch(e) {
		setSearchInput(e.target.value)
	}

	// handle search input onBlur
	function handleBlur() {
		searchInput.length === 0 && setSearchVisible(false)
	}

	// sort by listSort value
	const sortedItems = (items &&
		listSort === 'dateDesc' ? items.sort((a, b) => b.createdAt - a.createdAt) :
		listSort === 'dateAsc' ? items.sort((a, b) => a.createdAt - b.createdAt) :
		listSort === 'titleDesc' ? items.sort((a, b) => b.title.localeCompare(a.title)) :
		listSort === 'titleAsc' ? items.sort((a, b) => a.title.localeCompare(b.title)) :
		listSort === 'brandDesc' ? items.sort((a, b) => a.brand?.name && b.brand?.name ? b.brand.name.localeCompare(a.brand.name) : a.brand?.name ? -1 : 1) :
		listSort === 'brandAsc' ? items.sort((a, b) => a.brand?.name && b.brand?.name ? a.brand.name.localeCompare(b.brand.name) : a.brand?.name ? -1 : 1) :
		listSort === 'typeDesc' ? items.sort((a, b) => b.analysisType - a.analysisType) : // TODO: this sorts by video/animatic/still. We will need to sort on assetType (TVC, Social, OLV etc.)
		listSort === 'typeAsc' ? items.sort((a, b) => a.analysisType - b.analysisType) : null
	)

	// filter on search text value (title, project, user)
	const filteredItems = items && sortedItems.filter(item =>
		(item.title?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) ||
		(item.project?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) ||
		(item.brand?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) ||
		(item.user?.name?.toLowerCase().indexOf(searchInput.toLowerCase()) > -1) ||
		(item.analysisType === 2 && 'animatics'.indexOf(searchInput.toLowerCase()) > -1)
	)

	// do we have any analyses with type > 1 (i.e. animatics)? otherwise we don’t need the 'sort by type' button
	const multipleTypes = items?.find(item => item.analysisType && item.analysisType > 1) !== undefined
	const sortByTypeBtn = multipleTypes && <div key={3} style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={sortList}>Sort by type</div>

	const sortOrSearch = (searchVisible ?
		<>
			<input style={searchInputStyle}
				id="searchField"
				className="fs-object-title-input"
				tabIndex="-1"
				spellCheck="false"
				value={searchInput}
				onChange={updateSearch}
				onBlur={handleBlur}
			/>
			<div style={closeButtonStyle} onClick={e=>setSearchVisible(false)}>&times;</div>
		</>
		:
		<>
			<div key={0} style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={sortList}>Sort by date</div>
			<div key={1} style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={sortList}>Sort by title</div>
			<div key={2} style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={sortList}>Sort by brand</div>
			{sortByTypeBtn}
			<div key={4} style={sortButtonStyle} onMouseOver={onOver} onMouseOut={onOut} onClick={e=>setSearchVisible(true)}>Search</div>
		</>
	)

	const list = items && filteredItems.map((item,i) => <AnalysisListItem key={item.id} idx={i} item={item} projects={projectList} brands={brandList} updateProjects={getProjectList} updateBrands={getBrandList} forceUpdate={e=>setForceUpdate(forceUpdate+1)} />)

	return (
		<div style={containerStyle}>
			<h2 style={{color:colors.text}}>{showTeamAnalyses ? 'Team' : 'My'} analyses</h2>
			<div style={sortStyle}>
				{sortOrSearch}
			</div>
			<div style={analysisListStyle}>
				{list}
			</div>
		</div>
	)
}
