import React, { useState, useEffect } from 'react'
import getAspectRatioLabel from '../../../utils/videoAspectRatios'
import DropZone from './DropZone'
import styles from './styles.module.css'

// component function
export default function VideoOrImage(props) {

	const { setMediaType, setMediaFile, setMediaRatio } = props

	const [file, setFile] = useState()
	const [mediaFormat, setMediaFormat] = useState('')
	const [mediaDimensions, setMediaDimensions] = useState('')

	// get image/video width/height
	useEffect(() => {
		setMediaFormat('')
		if (file?.type.match('image.*')) {
			const img = new Image()
			img.src = window.URL.createObjectURL(file)
			img.onload = () => {
				img.width && setMediaFormat('Image')
				img.width && setMediaDimensions('(' + img.width + ' x ' + img.height + ' px)')
				img.width && setMediaType('image') // inform parent
				img.width && setMediaFile(file) // inform parent
				img.width && setMediaRatio(img.width/img.height) // inform parent
			}
		} else if (file?.type.match('video.*')) {
			const vid = document.createElement('video')
			vid.src = window.URL.createObjectURL(file)
			vid.addEventListener("loadedmetadata", function removeVideo() {
				vid.removeEventListener("loadedmetadata", removeVideo)
				vid.videoWidth && setMediaFormat(getAspectRatioLabel(vid.videoWidth/vid.videoHeight) + ' Video')
				vid.videoWidth && setMediaDimensions('(' + vid.videoWidth + ' x ' + vid.videoHeight + ' px)')
				vid.videoWidth && setMediaType('video') // inform parent
				vid.videoWidth && setMediaFile(file) // inform parent
				vid.videoWidth && setMediaRatio(vid.videoWidth/vid.videoHeight) // inform parent
				vid.src = ''
				vid.load()
			})
		} else { // error
			setMediaFile(file) // inform parent
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file])

	const formatTexts = mediaFormat && (
		<div className={styles.formatContainer}>
			<div>Format:</div>
			<div className={styles.format}>{mediaFormat}</div>
			<div>{mediaDimensions}</div>
		</div>
	)

	return (
		<>
			<div className={styles.sectionHeading}>1. Video or image</div>
			<div className={styles.container}>
				<DropZone fileAdded={(file) => setFile(file)} />
				{formatTexts}
			</div>
		</>
	)
}
